import React from 'react'

const AfterPayArrows = () => (
<svg width="24" height="24" viewBox="0 0 375 375" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <title>Afterpay_Sticker_BlackonMint</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Afterpay_Sticker_BlackonMint" fill-rule="nonzero">
            <g id="Sticker" fill="#B2FCE4">
                <circle id="Oval" cx="187.4" cy="187.4" r="187.4"></circle>
            </g>
            <g id="Loop" transform="translate(63.000000, 76.000000)" fill="#000000">
                <path d="M231.3,45.5 L196.7,25.7 L161.6,5.6 C138.4,-7.7 109.4,9 109.4,35.8 L109.4,40.3 C109.4,42.8 110.7,45.1 112.9,46.3 L129.2,55.6 C133.7,58.2 139.3,54.9 139.3,49.7 L139.3,39 C139.3,33.7 145,30.4 149.6,33 L181.6,51.4 L213.5,69.7 C218.1,72.3 218.1,79 213.5,81.6 L181.6,99.9 L149.6,118.3 C145,120.9 139.3,117.6 139.3,112.3 L139.3,107 C139.3,80.2 110.3,63.4 87.1,76.8 L52.1,97 L17.5,116.8 C-5.8,130.2 -5.8,163.9 17.5,177.3 L52.1,197.1 L87.2,217.2 C110.4,230.5 139.4,213.8 139.4,187 L139.4,182.5 C139.4,180 138.1,177.7 135.9,176.5 L119.6,167.2 C115.1,164.6 109.5,167.9 109.5,173.1 L109.5,183.8 C109.5,189.1 103.8,192.4 99.2,189.8 L67.2,171.4 L35.3,153.1 C30.7,150.5 30.7,143.8 35.3,141.2 L67.2,122.9 L99.2,104.5 C103.8,101.9 109.5,105.2 109.5,110.5 L109.5,115.8 C109.5,142.6 138.5,159.4 161.7,146 L196.8,125.9 L231.4,106.1 C254.6,92.6 254.6,58.9 231.3,45.5 Z" id="Path"></path>
            </g>
        </g>
    </g>
</svg>
)

export default AfterPayArrows