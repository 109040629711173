import React, { useEffect, useState } from "react"
import ProductCard from "../../molecules/ProductCard/ProductCard"
import BigComAPI from '../../../helpers/bigcommerce/BigComAPI';

import styles from'./ProductGrid.module.css'

const ProductGrid = ({
    products,
    removeFunction,
    columns = 4,
    inventories = [],
    loadInventory = false
}) => {
    const classNames = [styles.root];
    if (typeof styles[`col${columns}`] !== 'undefined') {
        classNames.push(styles[`col${columns}`]);
    }

    const [stockLoaded, setStockLoaded] = useState(false);
    const [productInventories, setProductInventories] = useState([]);
    const bigCom = BigComAPI();

    useEffect(() => {
        if (products && products?.length && !stockLoaded && loadInventory) {
            const _products = products.map(a => typeof a.node !== 'undefined' ? a.node : a)
                                      .map(a => {
                                          if (!('entityId' in a) && 'id' in a) {
                                              a.entityId = a.id;
                                              return a;
                                          }

                                          return a;
                                      })
                                      .map(a => a.entityId)
                                      .filter(a => productInventories.map(a => a.entityId).indexOf(a.entityId) < 0);
            if (_products.length) {
                setStockLoaded(true);
                bigCom.getStockLevel(_products, (_inventories) => {
                    setProductInventories([...productInventories, ..._inventories]);
                })
            }
        }
    }, [
        loadInventory,
        stockLoaded,
        products,
        productInventories,
        bigCom
    ]);

    if (products) {
        return (
            <div className={classNames.join(' ')}>
                {products.map((node, index) => {
                    if ('node' in node) {
                        return <ProductCard key={index} data={node.node} removeFunction={removeFunction} inventories={loadInventory ? productInventories : inventories} />
                    }
                    if ('sku' in node) {
                        return <ProductCard key={index} data={node} removeFunction={removeFunction} inventories={loadInventory ? productInventories : inventories} />
                    }
                    return null
                })}
            </div>
        )
    } else {
        return null;
    }
}

export default ProductGrid
