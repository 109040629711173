import React from 'react';

const Heart = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0279 2.89415C12.0864 1.72423 13.5905 1 15.2618 1C18.4373 1 21 3.56267 21 6.73816C21 10.9164 14.5933 16.3203 11.0279 18.7159C7.40669 16.3203 1 10.9164 1 6.73816C1 3.56267 3.56267 1 6.73816 1C8.46518 1 9.96936 1.72423 11.0279 2.89415Z"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
);

export default Heart;
