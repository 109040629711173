import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getStorage } from '../../../helpers/general';
import PriceContext from '../../../context/PriceProvider';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';

// INHERIT STYLES FROM PARENT COMPONENT

const Price = props => {
  const prices = useContext(PriceContext);
  const { entityId, price, calculated_price, sale_price, variant_price } = props;
  const styles = props.styles || {};

  const currencyData = useStaticQuery(graphql`
    query {
      allBigCommerceCurrencies(
        filter: { enabled: { eq: true }, is_default: { eq: true } }
      ) {
        edges {
          node {
            currency_code
            currency_exchange_rate
          }
        }
      }
    }
  `);
  let currencyCode =
    currencyData?.allBigCommerceCurrencies.edges[0].node.currency_code;
  let rate =
    currencyData?.allBigCommerceCurrencies.edges[0].node.currency_exchange_rate;
  const sessionCurrencyJson = getStorage('currency');
  if (sessionCurrencyJson) {
    const sessionCurrency = JSON.parse(sessionCurrencyJson);
    currencyCode = sessionCurrency.currentCurrency;
    rate = sessionCurrency.rates[currencyCode];
  }

  const calculatePrice = price => {
    return price * rate;
  };

  const latestProduct = {
    price,
    calculated_price,
    sale_price, 
    variant_price
  };

  let ignoreTax = false;
  if (prices && prices[entityId]) {
    ignoreTax = true;
    latestProduct.price = prices[entityId].price ? prices[entityId].price.tax_inclusive : null;
    latestProduct.calculated_price = prices[entityId].calculated_price ? prices[entityId].calculated_price.tax_inclusive : null;
    latestProduct.sale_price = prices[entityId].sale_price ? prices[entityId].sale_price.tax_inclusive : null;
    latestProduct.retail_price = prices[entityId].retail_price ? prices[entityId].retail_price.tax_inclusive : null;
  }

  return (
    <div className={styles.prices}>
      {latestProduct.sale_price !== 0 && latestProduct.sale_price !== null && latestProduct.sale_price !== latestProduct.price ? (
        <>
          <span className={`${styles.price} ${styles.onSale}`}>
            <CurrencyFormatter
              currency={currencyCode}
              amount={calculatePrice(latestProduct.variant_price ? latestProduct.variant_price : latestProduct.price)}
              ignoreTax={ignoreTax}
            />
          </span>
          {latestProduct.sale_price && (
            <span className={`${styles.price} ${styles.salePrice}`}>
              <CurrencyFormatter
                currency={currencyCode}
                amount={calculatePrice(latestProduct.calculated_price)}
                ignoreTax={ignoreTax}
              />
            </span>
          )}
        </>
      ) : (
        <span className={styles.price}>
          <CurrencyFormatter
            currency={currencyCode}
            amount={
              latestProduct.calculated_price
                ? calculatePrice(latestProduct.variant_price ? latestProduct.variant_price : latestProduct.calculated_price)
                : calculatePrice(latestProduct.variant_price ? latestProduct.variant_price : latestProduct.price)
            }
            ignoreTax={ignoreTax}
          />
        </span>
      )}
    </div>
  );
};

export default Price;
