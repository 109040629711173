import React from "react"
import Icon from "../Icon/Icon";
import styles from './Stars.module.css'

const Stars = ({ stars }) => {
    let _stars = 0;
    if (stars) {
        _stars = parseFloat(stars, 10);
    }

    return (
        <span className={styles.starsContainer}>
            <span>
                {[1,2,3,4,5].map((_item, _index) => (
                    <Icon symbol="star" key={_index} />
                ))}
            </span>
            <span style={{'width': `${parseFloat(_stars * 100 / 5, 10).toFixed(0)}%`}}>
                {[1,2,3,4,5].map((_item, _index) => (
                    <Icon symbol="star" key={_index} />
                ))}
            </span>
        </span>
    )
}

export default Stars
