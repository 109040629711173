import React from 'react'

const Cloud = ({uid}) => (
    <svg width="100%" height="32" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <pattern id={`cloud-${uid}`} x="50%" y="0" width="248" height="32" patternUnits="userSpaceOnUse">
            <path d="M248 12c-1.7-.2-2.9-.3-3.5-.6-1-.3-2-.8-2.9-1.5-2-1.4-3.7-3.4-6.2-5.3a18.4 18.4 0 00-12-4c-2.6 0-5 .5-6.9 1.2-2 .6-3.5 1.5-4.8 2.5-2.6 1.9-4.4 4-6.3 5.4-1.3 1-2.5 1.6-3.9 2-.8.2-1.6.3-2.8.3a10.5 10.5 0 01-6.4-2.1c-1.9-1.4-3.7-3.4-6.2-5.3a18.3 18.3 0 00-12-4c-2.6 0-5 .5-6.9 1.2-1.9.6-3.5 1.5-4.8 2.5-2.6 1.9-4.4 4-6.3 5.4-1.3 1-2.5 1.6-3.9 2-.8.2-1.6.3-2.8.3a10.5 10.5 0 01-6.3-2.1c-2-1.4-3.8-3.4-6.3-5.3a18.3 18.3 0 00-12-4c-2.6 0-4.9.5-6.8 1.2-2 .6-3.5 1.5-4.8 2.5-2.6 1.9-4.4 4-6.4 5.4-1.2 1-2.5 1.6-3.8 2-.8.2-1.7.3-2.8.3a10.5 10.5 0 01-6.4-2.1c-2-1.4-3.7-3.4-6.3-5.3a18.3 18.3 0 00-12-4c-2.5 0-4.9.5-6.8 1.2-2 .6-3.5 1.5-4.8 2.5-2.6 1.9-4.4 4-6.3 5.4-1.3 1-2.5 1.6-3.9 2-.8.2-1.7.3-2.8.3a10.5 10.5 0 01-6.4-2.1c-2-1.4-3.7-3.4-6.2-5.3a18.3 18.3 0 00-12-4c-2.6 0-5 .5-6.9 1.2-2 .6-3.5 1.5-4.8 2.5-2.6 1.9-4.4 4-6.3 5.4-1.3 1-2.5 1.6-3.9 2a11 11 0 01-4.4.2V32h248V12z" fill="currentColor" fillRule="nonzero"/>
            </pattern>
        </defs>

        <rect fill={`url(#cloud-${uid})`} width="100%" height="32" />
    </svg>
)

export default Cloud