import React, { useContext } from 'react';
import CartContext from '../../../context/CartProvider';

import Button from '../../atoms/Button/Button';

const AddToCartButton = ({
  children,
  productId,
  variantId,
  additionalAction,
  className,
  fullWidth,
  quantity,
  disabled = false
}) => {
  const value = useContext(CartContext);
  const addToCart = value && value.addToCart;
  const addingToCart = value && value.state.addingToCart;

  if (!productId) return null;

  if (className) {
    return (
      <span
        role="presentation"
        className={className}
        onClick={() => {
          addToCart(productId, variantId);
          additionalAction && additionalAction();
        }}
      >
        {children}
      </span>
    );
  } else {
    return (
      <div className="bc-form bc-addtocart-form">
        <div className="bc-product-form__product-message"></div>
        <Button
          level="primary"
          type="buttonSubmit"
          disabled={disabled || addingToCart === productId}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (quantity && parseInt(quantity, 10) > 0) {
              addToCart(productId, variantId, null, quantity);
            } else {
              addToCart(productId, variantId);
            }
            additionalAction && additionalAction();
          }}
          size={fullWidth && 'fullWidth'}
        >
          {addingToCart === productId ? 'Adding to Cart' : children}
        </Button>
      </div>
    );
  }
};

export default AddToCartButton;
