import React, { useEffect, useState } from 'react';
// import AuthContext from '../../../context/AuthProvider';
import Price from '../Price/Price'

// INHERIT STYLES FROM PARENT COMPONENT

import styles from './SpencilPrice.module.css'

const SpencilPrice = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { product, customFields, classNames, variantPrice } = props; 

  const [priceDisplay, setPriceDisplay] = useState('retail');


  let thePrices;
  if ('detailedPrices' in product) {
    thePrices = {
      price: product.detailedPrices.price.tax_inclusive,
      calculated_price: product.detailedPrices.calculated_price.tax_inclusive,
      sale_price: product.detailedPrices.sale_price.is_on_sale ? product.detailedPrices.sale_price.tax_inclusive : 0,
      variantPrice: variantPrice
    };
  } else {
    thePrices = {
      price: product.price,
      calculated_price: product.calculated_price,
      sale_price: product.sale_price,
      variantPrice: variantPrice
    };
  }

  const _classes = [styles.productPrice];
  if (classNames) {
    _classes.push(classNames);
  }

  useEffect(() => {
    if (priceDisplay !== 'retail') {
      setPriceDisplay('retail');
    }
  }, [priceDisplay]);

  return (
    <div className={_classes.join(' ')}>
      {priceDisplay === 'retail' && (
        <Price 
          key='retail'
          entityId={product.entityId} 
          price={thePrices.variantPrice ? thePrices.variantPrice : thePrices.price} 
          calculated_price={thePrices.calculated_price} 
          sale_price={thePrices.sale_price}
          variant_price = {thePrices.variantPrice}
          styles={styles} />
      )}
    </div>
  )
};

export default SpencilPrice;
