// import { useContext } from 'react';
// import AuthContext from '../../../context/AuthProvider';

const CurrencyFormatter = ({ currency, amount }) => { //, ignoreTax = false 
  // const authCtx = useContext(AuthContext);
  let displayAmount =  (typeof amount !== 'number' && parseFloat(amount?.replace('$', ''))) || amount;

  /* Set language display */
  const languageCode =
    typeof window !== 'undefined'
      ? window.navigator.language || 'en-AU'
      : 'en-AU';
  
  /* Format and return */
  const formattedPrice = new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency
  }).format(displayAmount);

  return amount === 0 || amount ? formattedPrice : 'No price available'
};

export default CurrencyFormatter;
