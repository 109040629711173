import React, { useEffect, useState } from 'react';
import { has } from 'lodash';
import { scroller } from 'react-scroll';
import InnerImageZoom from 'react-inner-image-zoom';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import styles from './ProductImages.module.css'

const ProductImages = props => {
    const { images, identifier, type } = props;

    const [sortedImages, setSortedImages] = useState([]);

    useEffect(() => {
        if (images && sortedImages.length === 0 && images.length > 0) {
            const imagesObj = [...images];
            imagesObj.sort((a, b) => a.sort_order - b.sort_order);//.sort(a => a.is_thumbnail ? -1 : 1);
            setSortedImages(imagesObj);
        }
    }, [images, sortedImages]);

    const onClickThumb = evt => {
        evt.preventDefault();
        if (window.innerWidth > 767) {
          scroller.scrollTo(
            evt.currentTarget.getAttribute('href').replace('#', ''),
            {
              duration: 800,
              delay: 0,
              offset: -180,
              smooth: 'easeInOutQuart'
            }
          );
        } else {
          const _parent = document.querySelector(
            `.${evt.currentTarget.getAttribute('data-image-class')}`
          ).parentElement;
          document
            .querySelectorAll(
              `.${evt.currentTarget.getAttribute('data-image-class')}`
            )
            .forEach((_el, _index) => {
              _el.classList.remove(
                evt.currentTarget.getAttribute('data-image-class')
              );
            });
    
          _parent
            // .querySelectorAll('img')
            .querySelectorAll('.productImageContainer')
            [
              parseInt(evt.currentTarget.getAttribute('data-key'), 10)
            ].classList.add(evt.currentTarget.getAttribute('data-image-class'));
        }
    
        return false;
    };

    return (
        <>
            {(type === 'thumbnails' && sortedImages && sortedImages.length > 1) && sortedImages.map((img, index) => (
                <a
                    href={`#${identifier}-${index + 1}`}
                    onClick={onClickThumb}
                    key={index}
                    data-key={index}
                    data-image-class={styles.productImageActive}
                    className={styles.productImageThumb}
                >
                    <img
                        loading="lazy"
                        src={
                            has(img, 'node') ? img.node.url : img.url_thumbnail
                        }
                        alt={img.description}
                    />
                </a>
            ))}

            {(type === 'gallery' && sortedImages) && sortedImages.map((img, index) => (
                // <img
                //     loading="lazy"
                //     src={
                //         has(img, 'node')
                //           ? img.node.url
                //           : has(img, 'url_zoom')
                //           ? img.url_zoom
                //           : img.url_standard
                //       }
                //     alt={img.description}
                //     id={`${identifier}-${index + 1}`}
                //     key={index}
                //     className={index === 0 ? styles.productImageActive : ''}
                // />
                <div id={`${identifier}-${index + 1}`} key={index} className={`productImageContainer ${index === 0 ? styles.productImageActive : ''}`}>
                  <InnerImageZoom 
                    src={
                      has(img, 'node')
                        ? img.node.url
                        : has(img, 'url_zoom')
                        ? img.url_zoom
                        : img.url_standard
                    }
                    zoomSrc={
                      has(img, 'node')
                        ? img.node.url
                        : has(img, 'url_zoom')
                        ? img.url_zoom
                        : img.url_standard
                    }
                    alt={img.description}
                    width={1000}
                    height={906}
                  />
                </div>
            ))}
            {(type === 'gallery' && (!sortedImages || sortedImages.length === 0)) && (
                <div className={styles.productGallery}>
                    <img
                        loading="lazy"
                        src="https://placehold.it?1000x1000?text=No+Images+Available"
                        alt="No product images"
                    />
                </div>
            )}
        </>
    )
}

export default ProductImages;