import React, { useContext, useState } from "react"
import Button from '../../atoms/Button/Button'
import Dialog from '../../atoms/Dialog/Dialog'
import ReviewContext from '../../../context/ReviewProvider';
import { reviewsApi } from '../../../helpers/reviews'

// import moment from 'moment'

import styles from'./ReviewForm.module.css'

const ReviewForm = ({ product, onReload = () => {} }) => {
    const ctxReview = useContext(ReviewContext)
    const showReviewForm = ctxReview && ctxReview.showReviewForm;
    const [errorState, setErrorState] = useState({ title: '', errors: {} });
    const [submitted, setSubmitted] = useState(false);

    const submitReviewForm = (e) => {
        e.preventDefault();
        // console.log('product', product)
        const formData = {};
        formData['sku'] = product.sku

        Array.from(new FormData(e.currentTarget)).map(a => { 
            // console.log('a', a) 
            if (a[0] === 'text') {
                formData['review'] = a[1];
            } else {
                formData[a[0]] = a[1]; 
            }
            
            return a; 
        })
        // formData['date_reviewed'] = moment().utc().format();
        // formData['status'] = 'pending';
        // console.log('formData', formData)

        reviewsApi('review/new', 'POST', formData)
            .then(({response, status}) => {
                // console.log(response, status);
                if ((response && status === 200) || status === 201) {
                    
                    setSubmitted(true);
                } else {
                    setErrorState(response);
                }
            })
            .catch(error => {
                console.error('error', error);
                setErrorState({ title: 'Error occurred', errors: {}});
            });
        return false;
    };

    const closeModal = () => {
        showReviewForm(false);
    }

    return (
        <>
            {ctxReview && (
                <Dialog 
                    open={ctxReview.state.open} 
                    bindState={ctxReview && ctxReview.showReviewForm}
                    title="Write a Review"
                    hideBtnOk
                    hideBtnCancel
                    disableBackdropClick>
                    <div className={`${styles.reviewForm} ${submitted ? styles.submitted : ''}`}>
                        <div>
                            {product && (
                                <>
                                    {product.images.length && (
                                        <img loading="lazy" src={product.images[0].url_standard} alt="Review Product" width="386" height="350" />
                                    )}
                                    {product.categories.length > 1 && (
                                        <h6>{product.categories[1].name}</h6>
                                    )}
                                    <h4>{product.name}</h4>
                                </>
                            )}
                        </div>
                        <div>
                            <form className={`form ${styles.writeReviewForm}`} method="post" onSubmit={submitReviewForm}>
                                <fieldset className="form-fieldset">
                                    <div className="form-field">
                                        <label className="form-label" htmlFor="rating-rate">
                                            Rating <sup>*</sup>
                                        </label>
                                        <select id="rating-rate" className="form-select" name="rating">
                                            <option value="">Select Rating</option>
                                            <option value="1">1 star (worst)</option>
                                            <option value="2">2 stars</option>
                                            <option value="3">3 stars (average)</option>
                                            <option value="4">4 stars</option>
                                            <option value="5">5 stars (best)</option>
                                        </select>
                                    </div>
                                    <div className="form-field">
                                        <label className="form-label" htmlFor="revfromname_input">
                                            Name <sup>*</sup>
                                        </label>
                                        <input type="text" name="name" id="revfromname_input" className="form-input" data-label="Name" />
                                    </div>
                                    <div className="form-field">
                                        <label className="form-label" htmlFor="email_input">
                                            Email <sup>*</sup>
                                        </label>
                                        <input type="text" name="email" id="email_input" className="form-input" />
                                    </div>
                                    <div className="form-field">
                                        <label className="form-label" htmlFor="revtitle_input">
                                            Review Subject <sup>*</sup>
                                        </label>
                                        <input type="text" name="title" id="revtitle_input" className="form-input" />
                                    </div>
                                    <div className="form-field form-field--textarea">
                                        <label className="form-label" htmlFor="revtext_input">
                                            Comments <sup>*</sup>
                                        </label>
                                        <textarea name="text" id="revtext_input" className="form-input"></textarea>
                                    </div>

                                    {errorState.errors && Object.keys(errorState.errors).length > 0 && (<ul className="form-field form-field--errors">
                                        {Object.values(errorState.errors).map((a, i) => (<li key={i}>{a}</li>))}
                                    </ul>)}

                                    {errorState.data && (<div className="form-field form-field--success">
                                        Your review has been submitted!
                                    </div>)}
                                    
                                    <div className="form-field form-field--submit">
                                        <Button type="inputSubmit" level="primary" 
                                            href="#" 
                                            value="Submit Review" 
                                            className="small" />
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>

                    <div className={`${styles.confirmation} ${submitted ? styles.submitted : ''}`}>
                        <h5>Thank you for your review!</h5>
                        <Button type="span" level="primary" onClick={() => closeModal()}>Close</Button>
                    </div>
                </Dialog>
            )}
        </>
    )
}

export default ReviewForm
