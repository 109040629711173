import React from 'react';
import WishlistContext from '../../../context/WishlistProvider';
import Icon from '../../atoms/Icon/Icon';

import styles from './WishlistButton.module.css';

const WishlistButton = ({ productId, variantId }) => {
  return (
    <WishlistContext.Consumer>
      {wishlistContext => {
        let wishlistCheck = { active: false, index: 0 };
        if (
          wishlistContext &&
          typeof wishlistContext.existsInWishlist === 'function'
        ) {
          wishlistCheck = {
            ...wishlistCheck,
            ...wishlistContext.existsInWishlist(productId, variantId)
          };
        }

        return (
          <div data-wishlist>
            <span
              role="presentation"
              className={`${styles.wishlist} ${
                wishlistCheck.active ? styles.active : ''
              }`}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                wishlistContext.updateSessionWishlist(productId, variantId);
              }}
            >
              <Icon symbol="heart" />
            </span>
          </div>
        );
      }}
    </WishlistContext.Consumer>
  );
};

export default WishlistButton;
