import React from 'react'

import Icon from '../../atoms/Icon/Icon'

import styles from './Callout.module.css'

function Callout ({children, design, color, link}) {
    const classDesign = `${design}Design`;
    const classColor = `${color}Color`;
    
    return (
        <>
            {link && (
                <a href={link} data-callout className={`${styles.callout} ${styles[classColor]} ${styles[classDesign]}`}>
                    <Icon symbol={design} />
                    <span className={styles.text}>{children}</span>
                </a>
            )}
            {!link && (
                <span data-callout className={`${styles.callout} ${styles[classColor]} ${styles[classDesign]}`}>
                    <Icon symbol={design} />
                    <span className={styles.text}>{children}</span>
                </span>
            )}
        </>
    )
}

export default Callout
