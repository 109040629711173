import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const IndividualContentBlock = ({sectionKey}) => {
    return (
        <StaticQuery
                query={graphql`
                query {
                    allBuilderModels {
                        individualContentBlocks( options: { cachebust: true } ) {
                            name
                            data {
                                content
                                sectionKey
                            }
                        }
                    }
                }
            `}
            render={data => {
                const found = data?.allBuilderModels.individualContentBlocks?.filter(data => data.data.sectionKey === sectionKey);
                if (found.length > 1) {
                    const content = found.filter(data => {
                        if (data.name.indexOf('(TRADE)') === -1) return true;

                        return false;
                    });
                    if (content.length > 0) {
                        return (
                            <div dangerouslySetInnerHTML={{ __html: content[0].data.content}}></div>
                        )
                    }
                } else if (found.length === 1) {
                    return (
                        <div dangerouslySetInnerHTML={{ __html: found[0].data.content}}></div>
                    )
                } else return null;
            }}
        />
    )
}

export default IndividualContentBlock