import React from 'react';

import CarouselWithCards from './widgets/CarouselWithCards';
import ProductRating from './widgets/ProductRating';

const ReviewsIoWidget = ({ sku, type }) => {
    if (type === 'carousel') {
        return <CarouselWithCards sku={sku} />
    } else if (type === 'rating') {
        return <ProductRating sku={sku} />
    }
}

export default ReviewsIoWidget;
