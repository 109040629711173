import React from 'react'
// import Icon from '../Icon/Icon';

// import Step1 from './step-1.jpg';
// import Step2 from './step-2.jpg';
// import Step3 from './step-3.png';
import MobileSteps from './Afterpay_AU_HowitWorks_Mobile_White@1x.png';
import DesktopSteps from './Afterpay_AU_HowitWorks_Desktop_White@1x.png';

import styles from './AfterPayInfo.module.css'

const AfterPayInfo = () => {
    return (
        <div className={styles.modal}>
            <div className={styles.imgContainer}>
                {MobileSteps && (
                    <img loading="lazy" src={MobileSteps} alt="Afterpay - How it works"  className={styles.afterpayMobile} />
                )}
                {DesktopSteps && (
                    <img loading="lazy" src={DesktopSteps} alt="Afterpay - How it works" className={styles.afterpayDesktop} />
                )}
            </div>
        </div>
    );
}

export default AfterPayInfo