import React from 'react';

const arrowRightDropshadow = () => (
  <svg width="14" height="22" viewBox="0 0 14 22" xmlns="http://www.w3.org/2000/svg">
    <filter id="arrowRightDropshadow" height="130%">
      <feGaussianBlur in="SourceAlpha" stdDeviation="1.4"/>
      <feOffset dx="0" dy="0" result="offsetblur"/>
      <feComponentTransfer>
        <feFuncA type="linear" slope="0.8"/>
      </feComponentTransfer>
      <feMerge> 
        <feMergeNode/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>
    <path d="M1 1l9 9-9 9" stroke="currentColor" strokeWidth="2" fill="none" transform="translate(1 1)" style={{'filter':'url(#arrowRightDropshadow)'}}/>
  </svg>
);

export default arrowRightDropshadow;
