import React, { useEffect, useRef, useState } from 'react';
import Slick from 'react-slick';
import Icon from '../Icon/Icon';
import './slick.css';
import './slick-theme.css';

const SliderPrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      data-customleftarrow
      className={className}
      style={{
        ...style,
        display: 'block',
        zIndex: '999',
        marginLeft: '38px',
      }}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
      onKeyDown={() => onClick()}
      role="button"
      tabIndex="0"
    >
      <Icon symbol="arrowLeftDropshadow" />
    </div>
  );
};

const SliderNextArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      data-customrightarrow
      style={{
        ...style,
        display: 'block',
        zIndex: '999',
        marginRight: '38px',
      }}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
      onKeyDown={() => onClick()}
      role="button"
      tabIndex="0"
    >
      <Icon symbol="arrowRightDropshadow" />
    </div>
  );
};

const Slider = props => {
  const { children, dots = true, customArrows, ...rest } = props;
  const [isInitialized, setIsInitialized] = useState(false);
  const sliderRef = useRef(null);
  const slideRefs = useRef([]);

  const customButtonsSetting = customArrows
    ? {
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />,
      }
    : null;

  const defaultSettings = {
    dots: dots,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    ...customButtonsSetting,
  };

  const settings = { ...defaultSettings, ...rest };

  useEffect(() => {

    const handleResize = () => {
      if (sliderRef.current && sliderRef.current.slickGoTo) {
        sliderRef.current.slickGoTo(0);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [children]);

  return (
    <Slick
    style={{ overflow:"hidden" }}
      ref={slider => {
        sliderRef.current = slider;
        if (slider && !isInitialized) {
          setIsInitialized(true);
        }
      }}
      {...settings}
    >
      {React.Children.map(children, (child, index) => (
        <div
          ref={el => slideRefs.current[index] = el}  
          style={{ height: "292px" }}
        >
          {child}
        </div>
      ))}
    </Slick>
  );
};

export default Slider;