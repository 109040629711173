import React from 'react';
import { Helmet } from "react-helmet"
import { scroller } from 'react-scroll';
import Stars from '../../../atoms/Stars/Stars';
import { useScript } from '../../../../helpers/general';

let scriptExists = false;

const ProductRating = ({ sku }) => {
    useScript('https://widget.reviews.io/rating-snippet/dist.js?_t=2021071121', 'ratingSnippet', () => {    
        if (typeof document !== 'undefined') {
            if (document.getElementById('reviewsRatingScript')) {
                document.getElementById('reviewsRatingScript').remove();
            }
            const script = document.createElement('script');
            script.id = 'reviewsRatingScript';
            script.innerText = `ratingSnippet("ruk_rating_snippet",{store: "spencil.com.au",color: "#f47e27",linebreak: true,text: "Reviews"});`;

            if (!scriptExists) {
                document.body.appendChild(script);
                scriptExists = true;
            }
            
        }
    });

    const scrollToReviews = () => {
        scroller.scrollTo(
            'reviews',
            {
                duration: 800,
                delay: 0,
                offset: -180,
                smooth: 'easeInOutQuart'
            }
        );
    }

    return (
        <>
            <Helmet>
                <script src="https://widget.reviews.io/product/dist.js" async="false"></script>
                <link rel="stylesheet" href="https://widget.reviews.io/rating-snippet/dist.css" />
                <style>
                    {`
                        .ruk_rating_snippet {padding-right: 5px;}
                        .ruk_rating_snippet br {display: none;}
                        .ruk_rating_snippet .ruk-rating-snippet-count {font-family: Poppins, sans-serif;}
                    `}
                </style>
            </Helmet>
            <div className="ruk_rating_snippet" data-sku={sku} onClick={() => scrollToReviews()} role="presentation">
                <Stars stars="0" /> <span className="ruk-rating-snippet-count">(No reviews)</span>
            </div>
        </>
    )
}

export default ProductRating;
