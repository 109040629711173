import { useContext } from 'react';
import GraphQLContext from '../../context/GraphQLProvider';

function BigComAPI() {
  const graphQLContext = useContext(GraphQLContext);

  const getStockLevel = (_entityIds = [], callback = () => {}) => {
    const query = graphQLContext && graphQLContext.query;
    query(
      `query($entityIds: [Int!] = []) {
          site {
            products (first: 50, entityIds: $entityIds) {
              edges {
                node {
                  entityId
                  inventory {
                    isInStock
                    aggregated {
                      availableToSell
                    }
                  }
                }
              }
            }
          }
        }`,
      { entityIds: _entityIds }
    )
      .then(response => {
        let _inventories = [];
        if (response.data && response.data.site.products.edges.length > 0) {
          _inventories = response.data.site.products.edges.map(b => {
            return {
              entityId: b.node.entityId,
              isInStock: b.node.inventory.isInStock,
              stockLevel: b.node.inventory?.aggregated?.availableToSell,
            }
          });
        }
        callback(_inventories);
      })
      .catch(error => {
        console.log("fetch list error:", error);
        callback([]);
      });
  }

  return {
    getStockLevel
  }
}

export default BigComAPI;