import React, { useEffect, useContext, useState } from 'react';

import { dissectTitle } from '../../../helpers/general';

import ReviewContext from '../../../context/ReviewProvider';

import Reviews from '../../organisms/Reviews/Reviews';
import ProductGrid from '../../organisms/ProductGrid/ProductGrid';
import SpencilPrice from '../../molecules/SpencilPrice/SpencilPrice';
import AddToCartButton from '../../molecules/AddToCartButton/AddToCartButton';
import StockStatus from '../../molecules/StockStatus/StockStatus';
import WishlistButton from '../../atoms/WishlistButton/WishlistButton';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import Dialog from '../../atoms/Dialog/Dialog';
import AfterPayInfo from '../../atoms/AfterPayInfo/AfterPayInfo';
import IndividualContentBlock from '../../atoms/IndividualContentBlock/IndividualContentBlock';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';

import styles from './ProductDetails.module.css';
import StockNotificationForm from '../StockNotificationForm/StockNotificationForm';

const ProductDetails = props => {
  const { product, type } = props;

  const ctxReview = useContext(ReviewContext);
  const showReviewForm = ctxReview && ctxReview.showReviewForm;
  // const defaultPrice = product.detailedPrices.calculated_price.as_entered;
  const defaultPrice = product.calculated_price;
  const defaultVariant = product.variants.filter(
    variant => variant.calculated_price === defaultPrice
  );
  const sortedVariants =
    (product.variants && product.variants.sort((a, b) => a.price - b.price)) ||
    null;

  const [maxQtyMessage, setMaxQtyMessage] = useState(false);
  const [displayAfterpay, setDisplayAfterpay] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [inStock, setInStock] = useState({});
  const [ctl, setCtl] = useState([]);
  const [pageInit, setPageInit] = useState(false);
  const [activeVariant, setActiveVariant] = useState(false);
  const [priceObj, setPriceObj] = useState(product || {});
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const selectedVariant = product.variants.find(
    variant => variant.entityId === activeVariant
  );

  // console.log(selectedVariant);
  // console.log(product, "product")
  // console.log(inStock, "inStock")
  const isBackInStockEnabled = product => {
    const backInStockField = product.custom_fields.find(
      field => field.name === 'Back In Stock'
    );
    // console.log(backInStockField, "backInStockField", product.custom_fields, "custom field")
    return backInStockField && backInStockField.value === 'true';
  };

  const inventoryCount =
    product &&
    typeof product.inventory_tracking !== 'undefined' &&
    typeof product.inventory_level !== 'undefined' &&
    typeof product.inventory_warning_level !== 'undefined' &&
    product.inventory_tracking === 'product' &&
    product.inventory_level;

  let designName = false;
  let productName = false;

  if (product) {
    [designName, productName] = dissectTitle(product.name);
  }

  const selectVariant = variant => {
    setActiveVariant(variant.entityId);
    setPriceObj(variant.price);
    setDropdownOpen(false);
  };

  useEffect(() => {
    const selectedVariant =
      product.variants.find(variant => variant.entityId === activeVariant) || product;
    // console.log('selectedVariant:', selectedVariant);
    const inventoryTracking = selectedVariant.inventory_tracking ?? product.inventory_tracking;
    // console.log('inventory_tracking:', inventoryTracking);
  
    if (selectedVariant) {
      let stockStatus;
      if (inventoryTracking === 'none') {
        // If inventory is not tracked, assume in stock. Existing logic from StockStatus.js
        stockStatus = { available: 'in-stock' };
      } else {
        stockStatus =
          selectedVariant.inventory_level > 0
            ? { available: 'in-stock' }
            : { available: 'no-stock' };
      }
      setInStock(stockStatus);
    }
  }, [activeVariant, product]);

  useEffect(() => {
    if (
      product &&
      typeof product.inventory_tracking !== 'undefined' &&
      typeof product.inventory_level !== 'undefined' &&
      typeof product.inventory_warning_level !== 'undefined' &&
      product.inventory_tracking === 'product' &&
      product.inventory_level < 1
    ) {
      // console.log('No inventory found', product);
      setQuantity(0);
    }

    // Set active variant
    if (
      product &&
      product.variants.length > 0 &&
      !activeVariant &&
      defaultVariant &&
      defaultVariant.length
    ) {
      setActiveVariant(defaultVariant[0].entityId);
      // to do: tie up variant with price
      setPriceObj(defaultVariant[0].price);
      // setStockObj({ ...product.variants[0], availability: product.availability, detail: {Call_For_Availability: product.detail?.Call_For_Availability}});
    } else if (product && product.variants.length === 0 && !activeVariant) {
      setActiveVariant(true);
      setPriceObj(true);
      // setStockObj(product);
    }

    // Set Complete the look
    if (
      product &&
      'completeTheLook' in product &&
      product.completeTheLook.length > 0 &&
      ctl.length === 0
    ) {
      let randomIndex = 0;
      let randomiseProducts = product.completeTheLook.filter(ctlProduct => {
        const rrpFound = ctlProduct.node.custom_fields.filter(
          field => field.name === 'RRP'
        );
        if (rrpFound.length === 0) {
          return true;
        }

        return false;
      });
      let currentIndex = randomiseProducts.length;

      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [randomiseProducts[currentIndex], randomiseProducts[randomIndex]] = [
          randomiseProducts[randomIndex],
          randomiseProducts[currentIndex]
        ];
      }

      setCtl(randomiseProducts.length > 1 ? randomiseProducts : false);
    }

    // Page init functions
    if (!pageInit) {
      setPageInit(true);
      // Open first description accordion at page load
      // const description = document.querySelector('[data-description]');
      // if (description) {
      //     const h4s = description.querySelectorAll('h4');
      //     if (h4s.length > 0) {
      //         h4s[0].classList.toggle('open');
      //         let nextElement = h4s[0].nextElementSibling;
      //         while (nextElement&& !nextElement.matches('h4')) {
      //             nextElement.classList.toggle(styles.active);
      //             nextElement = nextElement.nextElementSibling;
      //         }
      //     }
      // }
    }
  }, [product, ctl, pageInit, activeVariant]);

  const updateQuantity = e => {
    let quantity = e.target.value;
    let maxQtyMessage = false;

    if (inventoryCount && quantity > inventoryCount) {
      quantity = inventoryCount;
      maxQtyMessage = `Only ${inventoryCount} available`;
    } else if (quantity === '') {
      // Do nothing
    } else if (quantity < 1) {
      quantity = 1;
    }

    setMaxQtyMessage(maxQtyMessage);
    setQuantity(quantity);
  };

  const onClickDesc = evt => {
    if (evt.target.matches('h4')) {
      const toOpen = !evt.target.classList.contains('open');
      // minimise already open accordion
      const description = document.querySelector('[data-description]');
      [...description.querySelectorAll('h4')].map(h4 => {
        h4.classList.remove('open');
        return true;
      });
      [...description.querySelectorAll(`.${styles.active}`)].map(ele => {
        ele.classList.remove(styles.active);
        return true;
      });
      // toggle clicked
      if (toOpen) {
        evt.target.classList.add('open');
        let nextElement = evt.target.nextElementSibling;
        while (nextElement && !nextElement.matches('h4')) {
          nextElement.classList.add(styles.active);
          nextElement = nextElement.nextElementSibling;
        }
      }

      setTimeout(() => {
        const rect = description.getBoundingClientRect(),
          scrollLeft =
            window.pageXOffset || document.documentElement.scrollLeft,
          scrollTop = window.pageYOffset || document.documentElement.scrollTop,
          offset = document.querySelector('[data-header]').offsetHeight;
        const position = {
          top: rect.top + scrollTop - offset,
          left: rect.left + scrollLeft
        };

        window.scrollTo({
          top: position.top,
          behavior: 'smooth'
        });
      }, 350);
    }
  };

  const onClickSeemore = evt => {
    const el = evt.target.closest('#see-more-content');
    if (el && evt.currentTarget.contains(el)) {
      el.parentNode.remove();

      const description = document.querySelector('[data-description]');
      [...description.querySelectorAll('p')].map(ele => {
        ele.classList.add(styles.active);
        return true;
      });

      // el.parentNode.classList.add(styles.seeMoreHide);
    }
  };

  const onStatusUpdate = _status => {
    setInStock(_status);
  };

  const calculateAfterPay = data => {
    let thePrice = 0;
    if (priceObj) {
      thePrice = priceObj;
    } else if ('detailedPrices' in data) {
      if (data.detailedPrices.sale_price.is_on_sale) {
        thePrice = data.detailedPrices.sale_price.tax_inclusive;
      } else {
        thePrice = data.detailedPrices.price.tax_inclusive;
      }
    } else if ('sale_price' in data && data.sale_price > 0) {
      thePrice = data.sale_price;
    } else {
      thePrice = data.price;
    }

    return <CurrencyFormatter currency="AUD" amount={thePrice / 4} />;
  };

  return (
    <div className={styles.productDetails}>
      {designName && <div className={styles.designName}>{designName}</div>}
      {productName && <h1>{productName}</h1>}

      <div className={styles.rateWrap}>
        <Reviews
          product={product}
          reviewsList={true}
          sku={product.sku}
          type="rating"
        />
        {type === 'full' && (
          <>
            |
            <Button
              type="a"
              href="#"
              link
              onClick={e => {
                e.preventDefault();
                showReviewForm();
              }}
            >
              Add your review
            </Button>
          </>
        )}
      </div>

      <SpencilPrice
        key="price"
        classNames={styles.productPrice}
        product={product}
        customFields={product.custom_fields}
        variantPrice={priceObj}
      />

      <div key="afterpay" className={styles.priceDetails}>
        or 4 payments of {calculateAfterPay(product)} with
        <Icon symbol="afterpayArrows" />
        <span
          className={styles.afterPayHelp}
          role="presentation"
          onClick={() => setDisplayAfterpay(!displayAfterpay)}
        >
          <Icon symbol="helpCircle" />
        </span>
        <Dialog open={displayAfterpay} size="md" hideBtnCancel hideBtnOk>
          <AfterPayInfo />
        </Dialog>
      </div>

      {product.variants.length <= 1 && (
        <StockStatus
          product={selectedVariant || product}
          onStatusUpdate={onStatusUpdate}
        />
      )}

      <div key="warranty" className={styles.warranty}>
        {product.warranty}
      </div>

      <>
        {product.variants.length > 1 && (
          <div className={styles.variantOptionsWrap}>
            <p className={styles.variantOptionName}>
              {product.variants[0].option_values[0].option_display_name}
            </p>
            <div
              className={`${styles.variantOptions} ${
                dropdownOpen ? styles.open : ''
              }`}
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              <div className={styles.selectedVariant}>
                {(() => {
                  const selectedVariantObj = product.variants.find(
                    variant => variant.entityId === activeVariant
                  );
                  if (selectedVariantObj) {
                    const color = selectedVariantObj.option_values[0].label;
                    return (
                      <div style={{ marginTop: '12px', marginLeft: '21px' }}>
                        <span
                          className={styles.colorSwatch}
                          data-color={color}
                        ></span>
                        {color}
                      </div>
                    );
                  }
                })()}
              </div>
              <Icon symbol="arrowDown" />
              {dropdownOpen && (
                <div className={styles.optionsList}>
                  {product.variants.map((variant, variantIndex) => {
                    const color = variant.option_values[0].label;
                    return (
                      <span
                        role="presentation"
                        key={variantIndex}
                        className={`${styles.option} ${
                          activeVariant === variant.entityId
                            ? styles.active
                            : ''
                        }`}
                        onClick={() => selectVariant(variant)}
                      >
                        <span
                          className={styles.colorSwatch}
                          data-color={color}
                        ></span>
                        {color}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}

        <div key="quantity" className={styles.productQtyFav}>
          <div>
            <input type="text" value={quantity} onChange={updateQuantity} />
            {maxQtyMessage && (
              <span className={styles.maxQtyMessage}>{maxQtyMessage}</span>
            )}
          </div>
          <div>
            <WishlistButton
              productId={
                product.variants && Array.isArray(product.variants)
                  ? product.variants[0].product_id
                  : product.entityId
              }
              variantId={
                product.variants && Array.isArray(product.variants)
                  ? product.variants[0].entityId
                  : null
              }
            />
            Save for later
          </div>
        </div>

        <div key="addToCartButton" className={styles.addToCartButton}>
          {inStock?.available === 'po-stock' && (
            <span className={styles.preorderDetail}>
              {inStock?.preorder_message.replace(
                '%%DATE%%',
                inStock?.preorder_release_date
              )}
            </span>
          )}
          <AddToCartButton
            productId={product.entityId}
            variantId={activeVariant}
            disabled={inStock?.available === 'no-stock'}
            quantity={quantity}
          >
            {inStock?.available === 'no-stock'
              ? 'Out of Stock'
              : inStock?.available === 'po-stock'
              ? 'Pre-Order'
              : 'Add to cart'}
          </AddToCartButton>
        </div>
        <div key="productMessage" className={styles.productMessage}>
          <Icon symbol="giftbox" />
          <IndividualContentBlock sectionKey="PDP promo line" />
        </div>
      </>
      {inStock.available !== 'in-stock' && isBackInStockEnabled(product) && (
        <StockNotificationForm
          productId={product.entityId}
          variantId={activeVariant}
        />
      )}

      {type === 'full' && (
        <>
          {'description' in product && (
            <div
              key="description"
              className={styles.productDescription}
              onClick={onClickDesc}
              onKeyDown={() => {}}
              role="button"
              tabIndex="0"
              data-description
            >
              <div
                onClick={onClickSeemore}
                dangerouslySetInnerHTML={
                  (product.description.match(/<p>/g) || []).length > 1
                    ? {
                        __html: product.description
                          .replace('<h4>', '<h4 class="open">')
                          .replace('<p>', `<p class="${styles.active}">`)
                          .replace(
                            '</p>',
                            `</p><p class="seemore-container ${styles.seeMore}"><a id="see-more-content">See More...</a></p>`
                          )
                      }
                    : {
                        __html: product.description
                          .replace('<h4>', '<h4 class="open">')
                          .replace('<p>', `<p class="${styles.active}">`)
                      }
                }
                onKeyUp={() => {}}
                role="button"
                tabIndex={0}
                aria-label="Button"
              ></div>

              {product.height > 0 && product.width > 0 && (
                <div>
                  <h4>DIMENSIONS</h4>
                  <p>
                    {product.height}cm x {product.width}cm
                    {product.depth > 0 ? ` x ${product.depth}cm` : ''}
                  </p>
                </div>
              )}
            </div>
          )}
          {'relatedProducts' in product && product.relatedProducts.length > 0 && (
            <div key="relatedProducts" className={styles.completeTheLook}>
              <h3>This Goes With That...</h3>
              <ProductGrid
                products={product.relatedProducts.slice(0, 4)}
                columns="2"
                loadInventory={true}
              />
            </div>
          )}
          {(!('relatedProducts' in product) ||
            product.relatedProducts.length === 0) &&
            'completeTheLook' in product &&
            product.completeTheLook.length > 0 &&
            ctl && (
              <div key="completeTheLook" className={styles.completeTheLook}>
                <h3>Complete The Look</h3>
                <ProductGrid
                  products={ctl.slice(0, 4)}
                  columns="2"
                  loadInventory={true}
                />
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default ProductDetails;
