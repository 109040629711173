import React from 'react';

import styles from './Divider.module.css';

// Alphabetical order please

import Cloud from './cloud';
import Teeth from './teeth';

function Divider(props) {
  const generateUID = () => {
    var firstPart = (Math.random() * 46656) | 0;
    var secondPart = (Math.random() * 46656) | 0;
    firstPart = ('000' + firstPart.toString(36)).slice(-3);
    secondPart = ('000' + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
  };

  const uid = generateUID();

  switch (props.shape) {
    case 'cloud':
      return (
        <div className={`${styles.divider} ${props.invert ? styles.invert : ''}`}>
          <Cloud uid={uid} />
        </div>
      );
    case 'teeth':
      return (
        <div className={`${styles.divider} ${props.invert ? styles.invert : ''}`}>
          <Teeth uid={uid} />
        </div>
      );
    case 'abka':
      return <div className={`${styles.divider} ${styles.abka}`} />;
    default:
      return <span>Unknown divider: {props.shape}</span>;
  }
}

export default Divider;
