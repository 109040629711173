import React from 'react'

const Teeth = ({uid}) => (
    <svg width="100%" height="32" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <pattern id={`teeth-${uid}`} x="50%" y="0" width="297" height="32" patternUnits="userSpaceOnUse">
                <path fill="currentColor" fillRule="nonzero" d="M271.9 0l-24.8 16.4L222.4 0l-24.6 16.3L173.3 0l-24.8 16.4L123.7 0 99.2 16.3 74.6 0 50 16.4 25 0 0 16.6V32h297V16.6z"/>
            </pattern>
        </defs>

        <rect fill={`url(#teeth-${uid})`} width="100%" height="32" />
    </svg>
)

export default Teeth