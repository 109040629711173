/**
 * Reviews API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The BC API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { reviewsApi } from '../helpers/reviews'

    reviewsApi('endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */

async function reviewsApi(endpoint, method, body) {
    // console.log('endpoint', endpoint)
    const options = {
        method: method ? method : 'GET'
    };

    if (body) {
        let bodyString = body;
        // console.log(body);
        if (typeof body === 'object') {
            bodyString = JSON.stringify(body)
        }

        options.body = bodyString
    }

    const parseJson = async response => {
        const text = await response.text()

        try {
            const json = JSON.parse(text)
            return json
        } catch (err) {
            return text
        }
    }

    if (typeof window !== 'undefined' && 'fetch' in window) {
        return await fetch(`/.netlify/functions/reviewsio?endpoint=${btoa(endpoint)}`,
        // return await fetch(`https://api.reviews.co.uk/product/${endpoint}&store=spencil.com.au`,
            options
        ).then(async res => ({
            response: await parseJson(res),
            status: res.status,
        }))
    } else return {response: {}, status: 404}
}

export { reviewsApi }