import React, { useState } from 'react';
import { has } from 'lodash';
import { navigate } from 'gatsby';
import { dissectTitle } from '../../../helpers/general';
import { setStorage } from '../../../helpers/general';

import Slider from '../../atoms/Slider/Slider';
import WishlistButton from '../../atoms/WishlistButton/WishlistButton';
import Icon from '../../atoms/Icon/Icon';
import Callout from '../Callout/Callout';

import Price from '../../molecules/Price/Price';
import ProductQuickView from '../ProductQuickView/ProductQuickView';

import AddToCartButton from '../../molecules/AddToCartButton/AddToCartButton';

import styles from './ProductCard.module.css';

const ProductCardTemplate = ({ data, removeFunction, inventories = [] }) => {
  const [isQuickViewOpen, setIsQuickviewOpen] = useState(false);

  if (!data) return null;
  
  const getImages = product => {
    if (!product || (!product.images && !product.defaultImage)) return [];

    if (product && product.images) {
      return product.images && has(product.images, 'edges')
        ? product.images.edges.sort((a, b) => a.node.sort_order - b.node.sort_order).sort(a => a.node.is_thumbnail ? -1 : 1).map(image => image.node.url)
        : has(product.images[0], 'url_standard')
          ? product.images.sort((a, b) => a.sort_order - b.sort_order).map(image => image.url_standard)
          : ['https://placehold.it/260x260?text=Not+Found'];
    }

    if (product && product.defaultImage && product.defaultImage.url)
      return [product.defaultImage.url];

    return ['https://placehold.it/260x260?text=Not+Found']
  };

  const getVariantValue = key => {
    if (key === 'variantId') {
      if ('variants' in data) {
        // BC API generated
        if (Array.isArray(data.variants) && 'entityId' in data.variants[0]) {
          return data.variants[0].entityId;
        }
        // BC API generated
        if (Array.isArray(data.variants) && 'id' in data.variants[0]) {
          return data.variants[0].id;
        }
        // BC GraphQL
        if (
          'edges' in data.variants &&
          'entityId' in data.variants.edges[0].node
        ) {
          return data.variants.edges[0].node.entityId;
        }
      }
      return null;
    }
    if (key === 'product_id' && 'entityId' in data) {
      return data.entityId;
    }
    return null;
  };

  const getLink = () => {
    const prepend = ''; // '/product';
    const append = '';

    // BC API generated
    if ('custom_url' in data)
      return `${prepend}${data.custom_url.url}${append}`;
    // BC GraphQL
    else if ('path' in data) return `${prepend}${data.path}${append}`;
    // Not found
    else return '#';
  };

  const getId = () => {
    const prepend = 'product-'; // '/product';
    const append = '';

    // BC API generated
    if ('entityId' in data)
      return `${prepend}${data.entityId}${append}`;
    // Not found
    else return '#';
  };

  const getPrices = () => {
    if ('prices' in data)
      return {
        price: data.prices.price ? data.prices.price.value : null,
        calculated_price: data.prices.calculatedPrice
          ? data.prices.calculatedPrice.value
          : null,
        sale_price: data.prices.salePrice ? data.prices.salePrice.value : null,
        retail_price: data.prices.retailPrice
          ? data.prices.retailPrice.value
          : null
      };
    else if (typeof data.price === 'object')
      return {
        price: data.price[Object.keys(data.price)[0]]
          ? data.price[Object.keys(data.price)[0]].value
          : null,
        calculated_price: data.calculated_price[
          Object.keys(data.calculated_price)[0]
        ]
          ? data.calculated_price[Object.keys(data.calculated_price)[0]].value
          : null,
        sale_price: data.sale_price[Object.keys(data.sale_price)[0]]
          ? data.sale_price[Object.keys(data.sale_price)[0]].value
          : null
      };
    else
      return {
        price: data.price,
        calculated_price: data.calculated_price,
        sale_price: data.sale_price
      };
  };

  const onClick = (e, linkTo, navigateTo) => {
    e.preventDefault();
    e.stopPropagation();
    setStorage('navigateTo', navigateTo);
    navigate(linkTo);
  };

  const toggleQuickView = e => {
    e.preventDefault();
    e.stopPropagation();

    setIsQuickviewOpen(!isQuickViewOpen);
  };

  const onChangeQuickView = () => {
    setIsQuickviewOpen(false);
  };

  let inventory = inventories.filter(a => a.entityId === data.entityId);
  inventory = inventory.length ? inventory[0] : false;

  const images = getImages(data);
  
  const slideImages = [...images].reverse();

  const productId = getVariantValue('product_id');
  const variantId = getVariantValue('variantId');
  const [designName, productName] = dissectTitle(data.name);

  return (
    <>
      <ProductQuickView
        open={isQuickViewOpen}
        data={data}
        onTouchEnd={onChangeQuickView}
        productId={productId}
        variantId={variantId}
      />
      <a
        data-productcardroot
        className={styles.productCard}
        onClick={(e) => {
          onClick(e, getLink(), getId())
        }}
        onKeyPress={(e) => {
          onClick(e, getLink(), getId())
        }}
        href={getLink()}
        id={getId()}
        role="button"
        tabIndex="0"
        data-age={data.custom_fields?.filter(cf => cf.name === 'Age').map(field => field.value).join(';')}
        data-design={data.custom_fields?.filter(cf => cf.name === 'Design').map(field => field.value).join(';')}
        data-theme={data.custom_fields?.filter(cf => cf.name === 'Theme').map(field => field.value).join(';')}
        data-color={data.custom_fields?.filter(cf => cf.name === 'Colour').map(field => field.value).join(';')}
      >
        <div className={styles.productCardHead}>
          <span
            className={styles.viewButton}
            onClick={toggleQuickView}
            role="presentation"
          >
            <Icon symbol="view" className={styles.viewIcon} />
          </span>
          <WishlistButton productId={productId} variantId={variantId} />
        </div>
        <div className={styles.productMainCardBody}>
          <div className={styles.imageContainer}>
            <img
              loading="lazy"
              src={images[0]}
              alt={data.name}
              className={`${styles.productImage} ${styles.productImageHover}`}
            />

            <div className={styles.imageSlider}>
              <Slider dots={false} customArrows={true} autoplay={false}>
                {slideImages.map((image, index) => (
                  <div className={styles.productSliderImageWrapp} key={index}>
                    <img
                      loading="lazy"
                      src={image}
                      alt={data.name}
                      className={styles.productSliderImage}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            {data && data.sale_price > 0 && (
              <Callout design="starburst" color="red">SALE</Callout>
            )}
            {inventory && typeof inventory.stockLevel === 'number' && inventory.stockLevel > 0 && inventory.stockLevel < 5 && (
              <Callout design="starburst" color="purple">LESS<br/><small>THAN 5</small></Callout>
            )}
            {inventory && inventory.availability === 'Preorder' && (
              <Callout design="starburst" color="teal">PRE ORDER</Callout>
            )}
          </div>

          <div className={`${styles.textBox} ${styles.textBoxHover}`}>
            <div className={styles.productDetails}>
              <h5 className={styles.productName}>{productName}</h5>
              <h5 className={styles.productSku}>
                {/* {data.brand_name || (has(data.brand, 'name') && data.brand.name)} */}
                {designName}
              </h5>
              <Price
                entityId={data.entityId}
                {...getPrices()}
                styles={styles}
              />
            </div>

            <div className={styles.addCartButtonWrapp}>
              {(inventory === false || (inventory && (inventory.isInStock || inventory.availability === 'Preorder'))) && (
               <AddToCartButton
                productId={productId}
                variantId={variantId}
                fullWidth={true}
              >
                <Icon symbol="whiteBag" className={styles.viewIcon} />
              </AddToCartButton>
             )}

             {inventory && !inventory.isInStock && inventory.availability !== 'Preorder' && (<button className={styles.btnOutOfStock}>
               Out of Stock
             </button>)}
            </div>
          </div>
        </div>
        {removeFunction && (
          <span
            role="presentation"
            className={styles.removeIcon}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              removeFunction(
                getVariantValue('product_id'),
                getVariantValue('variantId')
              );
            }}
          >
            <Icon symbol="close" />
          </span>
        )}
      </a>
    </>
  );
};

export default ProductCardTemplate;
