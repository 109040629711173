import React from 'react'

const MultiColourCloudCheck = () => (
    <svg width="40" height="26" viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient x1="17%" y1="38.3%" x2="100%" y2="60.9%" id="multicolor-cloud">
                <stop stopColor="#EA53BD" offset="0%"/>
                <stop stopColor="#F5AC70" offset="21.6%"/>
                <stop stopColor="#ECD857" offset="43.4%"/>
                <stop stopColor="#B2E561" offset="69.9%"/>
                <stop stopColor="#74B4CB" offset="100%"/>
            </linearGradient>
        </defs>
        <path d="M129 1706.8l-.3-1a7 7 0 00-1.6-2.9 7.4 7.4 0 00-2-1.6c-1.4-.8-2.8-1.3-4.5-1.1h-.5a.6.6 0 01-.2-.5l-.4-2.8a8.3 8.3 0 00-4.2-5 7.5 7.5 0 00-9.3 2c-.4.3-.7.5-1.3.4a6 6 0 00-3.2 0 6 6 0 00-3.2 2c-.7.9-1.2 2-1.1 3.1 0 .7 0 .7-.6.8H96l-1.7.3c-1.1.4-2.1 1-3 1.8a7 7 0 00-2.1 3.6c-.3 1-.2 2.2 0 3.3a7 7 0 002.3 4 8 8 0 009.7 1c.3-.1.6-.1 1 .1 1.4 1 3 1.7 4.7 2.1 1.7.4 3.3.3 5 .2.6 0 1.2-.3 1.8-.4 1-.3 2-.7 2.8-1.2.6-.3 1-.6 1.8-.2 1.6.8 3.3.8 5 .3a7.4 7.4 0 005.6-8.3" transform="translate(-89 -1691)" fill="url(#multicolor-cloud)" fillRule="evenodd"/>
        <path d="M26 10L19.2588 18L15 14.4416" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default MultiColourCloudCheck